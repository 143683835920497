// 此mixin於行銷活動中有用到
export default {
  methods: {
    dateTimeValidationMixin(startTime, endTime) {
      if (!startTime || !endTime) {
        // 判斷有沒有輸入 開始時間 / 結束時間
        // 其中一個沒輸入就返回 false
        this.$myVS.openNoti('請輸入活動開始/結束時間', '', 'danger', 10000)
        return false
      } else if (Date.parse(startTime) >= Date.parse(endTime)) {
        // 判斷時間比大小
        this.$myVS.openNoti('結束時間必須比開始時間晚', '', 'danger', 10000)
        return false
      } else {
        return true
      }
    },
  },
}
